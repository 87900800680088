import React from 'react';
import ProductDetails from '../components/ProductDetails';
import ProductCertifications from '../components/ProductCertifications';
import ProductPress from '../components/ProductPress';
import ProductReviews from '../components/ProductReviews';
import RelatedProducts from '../components/RelatedProducts';
import CuralateProductGallery from '../components/CuralateProductGallery';

export default ({product, components = []}) => (
	<React.Fragment>
		{components.map((component, index) => {
			switch (component._type) {
				case 'productDetails':
					return (
						<ProductDetails
							index={index}
							key={component._key}
							{...product}
							{...component}
						/>
					);
				case 'productCertifications':
					return (
						<ProductCertifications
							index={index}
							key={component._key}
							{...product}
							{...component}
						/>
					);
				case 'productPress':
					return (
						<ProductPress
							index={index}
							key={component._key}
							{...product}
							{...component}
						/>
					);
				case 'productReviews':
					return (
						<ProductReviews
							index={index}
							key={component._key}
							{...product}
							{...component}
						/>
					);
			 case 'curalateProductGallery':
					return (
						<CuralateProductGallery
							index={index}
							key={component._key}
							sku={product.variants[0].sku}
							{...component}
						/>
					);
				case 'relatedProducts':
					return (
						<RelatedProducts
							index={index}
							key={component._key}
							products={product.relatedProducts}
							{...component}
						/>
					);

				default:
					return null;
			}
		})}
	</React.Fragment>
);
