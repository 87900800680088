import React, {useState, useRef, useEffect} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Radio from './Radio';
import {Link} from 'gatsby';
import Flickity from './Flickity';
import classNames from 'classnames';
import {encode} from '../utils/shopify-gid'
import InventoryProductProvider from './InventoryProductProvider';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import pluralize from 'magic-tricks/lib/pluralize';
import Select from './Select';
import getWholesaleVariant from '../utils/getWholesaleVariant';
import {connectToStore} from './Provider';
import Reveal from './Reveal';
import {Transition} from 'react-transition-group';
import {submitBackInStock} from '../api/klaviyo';
import KlaviyoPopup from './KlaviyoPopup';
import Helmet from 'react-helmet';
import findAccessiblityColor from '../utils/findAccessiblityColor';


const TRANSITION_DURATION = 200;

const DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	opacity: 0,
};

const TRANSITION_STYLES = {
	entering: {opacity: 0},
	entered: {opacity: 1},
	exited: {opacity: 0},
	exiting: {opacity: 0},
};

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	contain: false,
	wrapAround: true,
	prevNextButtons: false,
	pageDots: true,
	selectedAttraction: 0.1,
	friction: 0.8,
};

const ProductHero = ({
	promo_offer,
	isBuyWithPrimeEnabled,
	islayout2,
	productId,
	sku,
	title,
	cents: defaultCents,
	variantId: defaultVariantId,
	subscription,
	color,
	slug,
	drugName,
	activeIngredients,
	description = [],
	heroImages = [],
	medicineCabinetTitle,
	medicineCabinetDescription,
	medicineCabinetCta,
	guaranteeDetails = [],
	customer,
	app,
	variants = [],
	hideLowInventoryProduct,
	link,
	link2,
	linkTitle1,
	linkTitle2,
	tags,
	amazon_link,
	wallmart_link,
	findstore_link,
	buyonamazon_link,
	upc,
	hasAcceptedCookies,
}) => {
	const [quantity, setQuantity] = useState(1);
	const [intervalFrequency, setIntervalFrequency] = useState(
		subscription ? subscription.orderIntervalFrequencyOptions[0] : null,
	);
	const [isZoomed, setIsZoomed] = useState(false);
	const [zoomPosition, setZoomPosition] = useState([0, 0]);
	const [isSubscription, setIsSubscription] = useState(false);
	const [activeImageIndex, setImageIndex] = useState(0);
	const activeImage = heroImages[activeImageIndex];
	const [wholeSet, setWholeSet] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [klaviyoError, setKlaviyoError] = useState(false);
	const [userFilledOut, setUserFilledOut] = useState(false);
	const [klaviyoSuccess, setKlaviyoSuccess] = useState('Notify me when back in stock');

	const discountVariant = getWholesaleVariant(variants, customer);
	const cents = discountVariant.cents;
	const variantId = discountVariant.variantId;

	let subscriptionCents = cents;

	// Created this ternary to remove subscription support on Wholesale and Practitioners tags
	// RT - 08-02-21
	const isWholesaleItem = tags && ( tags.includes('wholesale') || tags.includes('practitioners') )
	? true
	: false

	if (subscription) {
		subscriptionCents =
			subscription.discountType === 'percentage'
				? cents - (Number(subscription.discountAmount) / 100) * cents
				: cents - Number(subscription.discountAmount) * 100;
	}

	if(isWholesaleItem && !wholeSet) {
		setWholeSet(true);
		setIsSubscription(false);
	}

	const moveZoomedImage = e => {
		e.preventDefault();

		const containerSize = document
			.querySelectorAll('.product-hero__zoom')[0]
			.getBoundingClientRect();

		const mouseX = e.clientX - containerSize.left;
		const mouseY = e.clientY - containerSize.top;
		const xPercent = (mouseX / containerSize.width) * 100;
		const yPercent = (mouseY / containerSize.height) * 100;

		setZoomPosition([xPercent, yPercent]);
	};
	const moveZoomedImageSlider = (e) => {
		e.preventDefault();
		const containerSize = e.target.getBoundingClientRect();
		const mouseX = e.clientX - containerSize.left;
		const mouseY = e.clientY - containerSize.top;
		const xPercent = (mouseX / containerSize.width) * 100;
		const yPercent = (mouseY / containerSize.height) * 100;
		setZoomPosition([xPercent, yPercent]);
	};

	const imageTilesCol = (
		<div className="col c1 show--md reveal__slide reveal__delay--2">
			<div className="pl2--xl">
				{heroImages.map((image, index) => (
					<button
						title={image.alt}
						aria-label={image.alt}
						key={index}
						className="db x link--opacity mb1"
						onClick={() => setImageIndex(index)}>
						<div className="x image size--3x4">
							<picture>
								<img
									alt={image.alt}
									src={`${image.url}?w=100&auto=format`}
								/>
							</picture>
						</div>
					</button>
				))}
			</div>
		</div>
	);

	const activeImageUrl = activeImage && activeImage.url
	? activeImage.url
	: false;

	const activeImageAlt = activeImage && activeImage.alt
	? activeImage.alt
	: '';

	const activeImageCol = (
		<div className="col c5 show--md reveal__slide reveal__delay--3">
			<div
				onMouseMove={e => moveZoomedImage(e)}
				className="image size--3x4 product-hero__zoom of--hidden">
				<picture
					onClick={() => setIsZoomed(!isZoomed)}
					onMouseOut={() => setIsZoomed(false)}>
					<source
						srcSet={`${activeImageUrl}?w=900&auto=format&q=75`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${activeImageUrl}?w=600&auto=format&q=75`}
						media="(min-width: 600px)"
					/>
					<img
						alt={activeImageAlt}
						src={`${activeImageUrl}?w=400&auto=format`}
					/>
				</picture>

				<link
					rel="preload"
					aria-label="image"
					href={`${activeImageUrl}?w=1400&auto=format&q=75`}
					as="image"
				/>

				<Transition
					in={isZoomed}
					mountOnEnter
					unmountOnExit
					appear
					timeout={{
						enter: 0,
						exit: TRANSITION_DURATION,
					}}>
					{state => (
						<picture
							className="product-hero__zoomed-image pen"
							style={{
								...DEFAULT_STYLE,
								...TRANSITION_STYLES[state],
								transformOrigin: `${zoomPosition[0]}% ${zoomPosition[1]}%`,
							}}>
							<img
								alt={activeImageAlt}
								src={`${activeImageUrl}?w=1400&auto=format&q=75`}
							/>
						</picture>
					)}
				</Transition>
			</div>
		</div>
	);

	const imageCarousel = (
		<Flickity className="product-hero__carousel" options={SLIDE_OPTIONS}>
			{heroImages.map((image, index) => (
				<div key={image._key} className="product-hero__slide mr2">
					<div className="image size--3x4 image_zoom of--hidden" onMouseMove={e => moveZoomedImageSlider(e)} onTouchMove={e => moveZoomedImageSlider(e)}>
						<picture onClick={() => {setIsZoomed(!isZoomed);setImageIndex(index);}}
								 onMouseOut={() => setIsZoomed(false)}>
							<source
								srcSet={`${image.url}?w=900&auto=format&q=75`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${image.url}?w=600&auto=format&q=75`}
								media="(min-width: 600px)"
							/>
							<img
								alt={image.alt}
								src={`${image.url}?w=400&auto=format`}
							/>
						</picture>
						{
							activeImageIndex==index && 			<Transition
								in={isZoomed}
								mountOnEnter
								unmountOnExit
								appear
								timeout={{
									enter: 0,
									exit: TRANSITION_DURATION,
								}}>
								{state => (
									<picture
										className="zoomed-image pen"
										style={{
											...DEFAULT_STYLE,
											...TRANSITION_STYLES[state],
											transformOrigin: `${zoomPosition[0]}% ${zoomPosition[1]}%`,
										}}>
										<img
											alt={activeImageAlt}
											src={`${activeImageUrl}?w=1400&auto=format&q=75`}
										/>
									</picture>
								)}
							</Transition>
						}

					</div>
				</div>
			))}
		</Flickity>
	);

	const medicineCabinetNote = (
			<div  className={classNames('', {
				'bt--black pt3':!islayout2 || (!hideLowInventoryProduct && islayout2),
			})}>
			<div className="rich-text sans--sm-md">
				<BlockContent
					blocks={medicineCabinetTitle}
					serializers={richText}
				/>
			</div>
			<div className="rich-text mb1">
				<BlockContent
					blocks={medicineCabinetDescription}
					serializers={richText}
				/>
			</div>
			<p>
				<Link
					to="/cabinets/new"
					className="button--pill--primary invert" aria-label={medicineCabinetCta}>
					{medicineCabinetCta}
				</Link>
			</p>
		</div>
	);

	const guaranteeNote = (
		<div className="rich-text">
			<BlockContent blocks={guaranteeDetails} serializers={richText} />
		</div>
	);

	const descriptionNote = (
		<div className="rich-text line-break">
			<BlockContent blocks={description} serializers={richText} />
		</div>
	);

	const subscriptionOption = !!subscription && !isWholesaleItem && (

		<div
		  id="subscribe-container"
			className={classNames('p1 product-hero__form mb1', {
				active: isSubscription,
			})}
			style={{'--backgroundColor': color}}>
			<div className="df fdr ais jcs">
				<Radio
					onClick={() => setIsSubscription(true)}
					className="fg0"
					active={isSubscription}
				/>
				<div
					onClick={() => setIsSubscription(true)}
					className={classNames('x fg1 rich-text pl1', {
						'sans--sm-md fw--800': isSubscription,
					})}>
					<div className="df fdr jcb">
						<div>
							Subscribe and Save{' '}
							{subscription.discountType === 'percentage'
								? `${subscription.discountAmount}%`
								: centsToPriceNoTrailingZeros(
										subscription.discountAmount * 100,
								  )}
						</div>
						<div className="tr">
							<span
								className={classNames('fw--400 strike', {
									o5: !isSubscription,
								})}>
								{centsToPriceNoTrailingZeros(cents)}
							</span>{' '}
							{centsToPriceNoTrailingZeros(subscriptionCents)}
						</div>
					</div>
					{isSubscription && (
						<div className="mt1 row fw--400">
							<div className="col c8--md">
								<Select
									label="Deliver every..."
									value={intervalFrequency}
									triggerClassName="cart-item__frequency-select"
									dropdownAlignment="top left right"
									optionClassName="sans--xs--md link--opacity"
									options={subscription.orderIntervalFrequencyOptions.map(
										option => ({
											title: `Delivery every ${option} ${pluralize(
												subscription.orderIntervalUnit,
												option,
											)}`,
											value: option,
										}),
									)}
									onChange={intervalFrequency =>
										setIntervalFrequency(intervalFrequency)
									}
								/>
								<p className="sans--xs mt1">
									Change or cancel anytime.
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>

	);

	const oneTimeOption = !!subscription && (
		<div
			className={classNames('p1 product-hero__form', {
				active: !isSubscription,
			})}
			style={{'--backgroundColor': color}}>
			<div className="df fdr ais jcs">
				<Radio
					onClick={() => setIsSubscription(false)}
					className="fg0"
					active={!isSubscription}
				/>
				<div
					onClick={() => setIsSubscription(false)}
					className={classNames('x fg1 rich-text pl1', {
						'sans--sm-md fw--800': !isSubscription,
					})}>
					<div className="df fdr jcb">
						<div>One Time Purchase</div>
						<div>{centsToPriceNoTrailingZeros(cents)}</div>
					</div>
				</div>
			</div>
		</div>
	);

	const quantityForm = (
		<div className="df fdr">
			<button
				aria-label="Remove 1"
				title="minus"
				onClick={quantity > 1 ? () => setQuantity(quantity - 1) : null}
				className="db link--opacity">
				<img
					alt="Remove 1"
					src="/images/icon-minus-cart.svg"
					className="db"
				/>
			</button>
			<p className="ml1 mr1">{quantity}</p>
			<button
				aria-label="Add 1"
				title="add"
				onClick={() => setQuantity(quantity + 1)}
				className="db link--opacity">
				<img
					alt="Remove 1"
					src="/images/icon-plus-cart.svg"
					className="db"
				/>
			</button>
		</div>
	);

	const productForm = (buttonText, oneTimePrice) => {
		return(
		<InventoryProductProvider
			productId={productId}
			renderLoading={() => (
				<button title="Loading" aria-label="Loading" disabled className="button--primary db x">
					Loading…
				</button>
			)}>
			{({product, isAddingItem, addItemAndOpenCart}) => {
				const onAddToCart = () => {
					let customAttributes = [];
					if (subscription) {
						customAttributes.push({
							key: '_subscription_options',
							value: JSON.stringify(subscription),
						});
					}
					if (subscription && isSubscription) {
						customAttributes = [
							...customAttributes,
							{
								key: 'subscription_id',
								value: subscription._id,
							},
							{
								key: 'shipping_interval_frequency',
								value: intervalFrequency.toString(),
							},
							{
								key: 'shipping_interval_unit_type',
								value: subscription.orderIntervalUnit,
							},
						];
					}
					const encodedVariantId = encode(
						'ProductVariant',
						variantId,
						{
							accessToken:
								process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
						},
					);

					addItemAndOpenCart([
						{
							quantity,
							variantId: encodedVariantId,
							customAttributes,
						},
					]).then(() => addToCartAnalytics() );
				};

				const addToCartAnalytics = () => {
					if (app.analytics) {
						app.analytics.addToCart({
							_productId: productId,
							id: discountVariant.sku,
							name: title,
							brand: 'Genexa',
							category: 'Medication',
							variant: discountVariant.title,
							quantity: 1,
							price: Number(discountVariant.price),
						});
					}
				}

				return (
					<button
						title="Add to Bag"
						aria-label="Add to Bag"
						onClick={onAddToCart}
						disabled={isAddingItem}
						className={classNames('button--primary db x', {
							loading: isAddingItem,
						})}>
						{`${buttonText} - `}
						{isSubscription && !oneTimePrice
							? centsToPriceNoTrailingZeros(
									subscriptionCents * quantity,
							  )
							: centsToPriceNoTrailingZeros(cents * quantity)}
					</button>
				);
			}}
		</InventoryProductProvider>
	)};

	const stickyproductForm = (buttonText, oneTimePrice) => {
		return(
		<InventoryProductProvider
			productId={productId}
			renderLoading={() => (
				<button title="Loading" aria-label="Loading" disabled className="button--primary db x">
					Loading…
				</button>
			)}>
			{({product, isAddingItem, addItemAndOpenCart}) => {
				const onAddToCart = () => {
					let customAttributes = [];
					if (subscription) {
						customAttributes.push({
							key: '_subscription_options',
							value: JSON.stringify(subscription),
						});
					}
					if (subscription && isSubscription) {
						customAttributes = [
							...customAttributes,
						];
					}
					const encodedVariantId = encode(
						'ProductVariant',
						variantId,
						{
							accessToken:
								process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
						},
					);

					addItemAndOpenCart([
						{
							quantity,
							variantId: encodedVariantId,
							customAttributes,
						},
					]).then(() => addToCartAnalytics() );
				};

				const addToCartAnalytics = () => {
					if (app.analytics) {
						app.analytics.addToCart({
							_productId: productId,
							id: discountVariant.sku,
							name: title,
							brand: 'Genexa',
							category: 'Medication',
							variant: discountVariant.title,
							quantity: 1,
							price: Number(discountVariant.price),
						});
					}
				}

				return (
					<button
						title={isSubscription && !oneTimePrice ? buttonText+' - '+centsToPriceNoTrailingZeros( subscriptionCents * quantity, ) : buttonText+' - '+centsToPriceNoTrailingZeros(cents * quantity)}
						aria-label={isSubscription && !oneTimePrice ? buttonText+' - '+centsToPriceNoTrailingZeros( subscriptionCents * quantity, ) : buttonText+' - '+centsToPriceNoTrailingZeros(cents * quantity)}
						onClick={onAddToCart}
						disabled={isAddingItem}
						className={classNames('button--primary db x', {
							loading: isAddingItem,
						})}>
						{`${buttonText} - `}
						{isSubscription && !oneTimePrice
							? centsToPriceNoTrailingZeros(
									subscriptionCents * quantity,
							  )
							: centsToPriceNoTrailingZeros(cents * quantity)}
					</button>
				);
			}}
		</InventoryProductProvider>
	)};

	const inStock = !hideLowInventoryProduct && (
		<div>
			<div className="mb2">
				{/*oneTimeOption*/}
				{/*subscriptionOption*/}
			</div>
			<div className="df fdr aic">
				<div className="fg1">{/*productForm('Add to Bag', false)*/}</div>
				<div className="fg0 pl2">{/*quantityForm*/}</div>
			</div>
		</div>
	);

	// Deeplink into the Review section
	const deepLinkIntoReviews = () => {
		var offsets = document.getElementById('product-reviews').getBoundingClientRect();
		var top = offsets.top - 100;
		window.scrollTo({top: top, left: 0, behavior: 'smooth' });
	}

	// Call to Netlify Function
	// This returns API fetch to Klaviyo
	// AWS - Lamda function
	const callToKlaviyoAPI = async (email) => {
		if(email && email !== '') {
			const response = await submitBackInStock(email, variantId);
			const data = JSON.parse(response);

			// if success
			if(data && data.status > 200) {
				setKlaviyoError(data.message)
			} else {
				setKlaviyoError('');
				setPopupVisible(false);
				setUserFilledOut(true);
				setKlaviyoSuccess("You're in! We'll let you know when it's back.");
			}
		}
	}

	// Open The Klaviyo Modal
	const openKlaviyoPopup = () => {
		if(!userFilledOut) {
			setPopupVisible(true)
		}
	}

	// Close The Klaviyo Modal
	const closePopup = () => {
		setPopupVisible(false)
	}

	const lowInventoryMessage = hideLowInventoryProduct && (
		<div className={classNames('rich-text lowinventory', {
			'mb2': islayout2,
		})}>
			<p className="tc tl--md sans--sm-md--md fw--800">We're sorry, this item is out of stock.</p>
			<p className="mt1">Please select one of the options below to find this product online or in a store near you.</p>
			<div className="mt mb">
				{linkTitle1 && !islayout2 &&
					<div className="mt1"><a href={link} target="_blank" className="button--pill--primary invert no--border" aria-label={linkTitle1} >{linkTitle1}</a></div>
				}
				{ linkTitle2 && !islayout2 &&
				<div className="mt1"><a href={link2} target="_blank" className="button--pill--primary invert no--border"  aria-label={linkTitle2} >{linkTitle2}</a></div>
				}
				<div className="back__in__stock__button button--pill--primary invert no--border" onClick={() => openKlaviyoPopup()}>{klaviyoSuccess}</div>

			</div>
		</div>
	);

	const retailPartnersCol = ((upc && islayout2) &&
		<div className="pt1 mb2">
			{/*<div class="mb2">
			{(buyonamazon_link && !hideLowInventoryProduct) && <a
						href={buyonamazon_link}
						className="db button--pill--primary red" aria-label="Buy now on Amazon" target="_blank">
						Buy now on Amazon
			</a>}
			</div>*/}
				{/*findstore_link  && <><div className="fw--800" style={{color:findAccessiblityColor('#fc6b70')}}> {!hideLowInventoryProduct && 'or shop Genexa in your favorite stores!'} {hideLowInventoryProduct && 'This product is temporarily unavailable on Amazon, but you can find it in your favorite retail store!'} </div>
				<div class="mt2 mb3">
					{<a
					aria-label= 'Where to buy' 
					href={findstore_link}
					className='button--pill--primary invert' style={{ fontSize: 20 }} target='_blank'>
						<span>
							<span className="show--md">Where to buy</span>
						</span>
			</a>
				</div>
				
				</>
					*/}

	{upc &&	<div class="mt2 mb3">		
			<div APO={upc} id="destini-locator" class="destini-locator-class" locator-id="2011" alpha-code="7DB" locator-name="Genexa Product Page OCL Widget" client-id="genexa" destini-header-name="" destini-header-image=""></div>
				</div>
	}
			{/* promo_offer && (<picture>
					<img alt={promo_offer.alt} src={`${promo_offer.url}?w=600&auto=format&q=75`}/>
				</picture>
			)*/}

		</div>


	);
	//Destini script loading script 
	useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://lets.shop/productWidgetSnippet.js?id=destini-locator";
        script.async = true;
    
        script.onload = () => {
          // Access functions or variables defined in your_script.js here
        //  console.log("Script loaded!");
          // Call functions or do operations with what's available from the script
        };
    
        document.body.appendChild(script);
    
        return () => {
          // Cleanup: remove the script when the component is unmounted
          document.body.removeChild(script);
        };
      }, []);
	const detailsCol = (
		<div className="col c6--md c4--lg reveal__slide reveal__delay--4">
			<h1 className="tc tl--md serif--lg serif--xxl--lg mb1">{title}</h1>
			<p className="tc tl--md sans--sm-md--md fw--800">{drugName}</p>
			<p className="tc tl--md sans--sm-md--md mb2">{activeIngredients}</p>

			<div className="product-hero__reviews tc tl--md" onClick={ () => deepLinkIntoReviews() }>
				<div
					data-bv-show="inline_rating"
					data-bv-product-id={productId}
				/>
			</div>
			<p className="tc tl--md sans--sm mb2">{!islayout2 && "The first clean medicine company"}</p>

			<div className="hide--md mb6 reveal__slide reveal__delay--5">
				{imageCarousel}
			</div>
			{descriptionNote}

			<div className="mt3 mb3">
				{!hideLowInventoryProduct && retailPartnersCol}
				{/*inStock*/}
				{/*lowInventoryMessage*/}
				{hideLowInventoryProduct && retailPartnersCol}
				
			</div>

			<div className="mb3">{/*medicineCabinetNote*/} </div>
			{/*guaranteeNote*/}
			
		</div>
	);

 	//
	const deepLinkIntoSubscribe = () => {
		setIsSubscription(true)
		var offsets = document.getElementById('subscribe-container').getBoundingClientRect();
		var top = offsets.top - 100;
		window.scrollTo({top: top, left: 0, behavior: 'smooth' });
	}

	return (
		<div className="mt3 mb3 mt5--lg  of--hidden">
		<KlaviyoPopup callToKlaviyoAPI={callToKlaviyoAPI} closePopup={closePopup} isVisible={popupVisible} klaviyoError={klaviyoError} />
			<Reveal>
				<div className="grid-container contained">
					<p className="show--md mb5 fw--800">
						<Link className="link--opacity" to="/collections/all" aria-label="Shop All">
							Shop All
						</Link>{' '}
						/{' '}
						<Link
							aria-label={title}
							className="link--opacity"
							to={`/products/${slug}`}>
							{title}
						</Link>
					</p>
					<div className="row align--center">
						{imageTilesCol}
						{ activeImageCol }
						{detailsCol}
					</div>
				</div>
			</Reveal>
			{/* {!hideLowInventoryProduct && hasAcceptedCookies &&
			<div className="sticky__atc">
				<div className="x">
					<div className="grid-container">
						<div className="df fdr jcb aic spacingalign">
							<p className="fw--600 tc tl--md sans--sm-md--md stickytitle">{title}</p>
							<div className="customalignment">
								<div className="subscript__and__save" onClick={() => deepLinkIntoSubscribe()}>Subscribe & Save</div>
								<div className="atc__button">{stickyproductForm('Buy Now', true)}</div>
								<div className="atc__quantity">{quantityForm}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			} */}
			
		</div>

	);
};

const mapStateToProps = ({customer,hasAcceptedCookies}) => ({
	customer,
	hasAcceptedCookies,
});

export default connectToStore(mapStateToProps)(ProductHero);
