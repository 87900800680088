const {fetch} = global;
const ENDPOINT = `${process.env.GATSBY_FUNCTION_PATH}/klaviyo`;

export const submitBackInStock = async (email, variantId) => {
	return await fetch(ENDPOINT, {
		method: 'POST',
		body: JSON.stringify({
			email,
			variantId
		})
	})
	.then(res => res.json());
};
