import React from 'react';
import ProductTileRelated from './ProductTileRelated';
import {connectToStore} from './Provider';
import {encode} from '../utils/shopify-gid';
import Flickity from './Flickity';
import getWholesaleVariant from '../utils/getWholesaleVariant';
import getApp from 'magic-tricks/lib/getApp';

const SLIDE_OPTIONS = {
	cellAlign: 'left',
	freeScroll: true,
	contain: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

const RelatedProducts = ({
	products = [],
	title,
	isAddingItem,
	addItemAndOpenCart,
	customer,
}) => {
	const inventoryProducts = products.filter(product => !product.hideLowInventoryProduct);
	const productTiles = inventoryProducts.map(product => {
		const discountVariant = getWholesaleVariant(product.variants, customer);
		const cents = discountVariant.cents;
		const variantId = discountVariant.variantId;

		const onAdd = () => {
			const app = getApp();
			const {subscription} = product;
			const customAttributes = [];
			console.log('app', app)
			if (subscription) {
				customAttributes.push({
					key: '_subscription_options',
					value: JSON.stringify(subscription),
				});
			}

			const encodedVariantId = encode('ProductVariant', variantId, {
				accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
			});

			// Track add to cart
			if (app.analytics) {
				app.analytics.addToCart({
					_productId: product.productId,
					id: discountVariant.sku,
					name: product.title,
					brand: 'Genexa',
					category: 'Medication',
					variant: discountVariant.title,
					quantity: 1,
					price: Number(discountVariant.price),
				});
			}

			return addItemAndOpenCart([
				{
					quantity: 1,
					variantId: encodedVariantId,
					customAttributes,
				},
			]);
		};

		return (
			<ProductTileRelated
				productId={product.productId}
				image={product.mainImageInverted}
				title={product.title}
				cents={cents}
				slug={product.slug}
				isAdding={isAddingItem}
				onAdd={onAdd}
				buyonamazon_link={product.buyonamazon_link}
			/>
		);
	});

	return (
		<>
	{ productTiles.length &&
	(<div className="bg--gray-bg pt6 pb6 pt12--md pb12--md of--hidden">
			<div className="grid-container contained">
				<h2 className="serif--lg serif--xxl--lg mb3 mb5--md tc color--black">
					{title}
				</h2>
				<div className="row align--center">
					<div className="col c10--lg">
						<div className="row gutter--vertical show--md gutter--large--xl">
							{productTiles.map((tile, index) => (
								<div key={index} className="col c4">
									{tile}
								</div>
							))}
						</div>
						<Flickity
							className="product-carousel hide--md"
							options={SLIDE_OPTIONS}>
							{productTiles.map((tile, index) => (
								<div
									key={index}
									className="product-carousel__item mr3">
									{tile}
								</div>
							))}
						</Flickity>
					</div>
				</div>
			</div>
		</div>)
        }
		</>
	);
};

const mapStateToProps = ({isAddingItem, customer}) => ({
	isAddingItem,
	customer,
});

const mapStoreToProps = ({addItemAndOpenCart}) => ({
	addItemAndOpenCart,
});

export default connectToStore(
	mapStateToProps,
	mapStoreToProps,
)(RelatedProducts);
