import React from 'react';

export default ({
	name,
	image,
	description,
	sku,
	price,
	url,
}) => {
	const schema = {
		'@context': 'https://schema.org/',
		'@type': 'Product',
		name,
		image,
		description,
		sku,
		mpn: sku,
		brand: {
			'@type': 'Thing',
			name: 'Genexa'
		},
		offers: {
			'@type': 'Offer',
			url,
			'priceCurrency': 'USD',
			price,
			itemCondition: 'https://schema.org/NewCondition',
			availability: 'https://schema.org/InStock',
			seller: {
				'@type': 'Organization',
				name: 'Genexa'
			}
		},
	};

	return (
		<div dangerouslySetInnerHTML={{__html: `<script type="application/ld+json">${JSON.stringify(schema)}</script>`}} />
	);
};