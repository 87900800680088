import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export default ({
	color,
	symptomsTitle,
	ingredientsTitle,
	symptoms = [],
	ingredients = [],
	productDetails = [],
}) => {
	const symptomList = (
		<div className="mb5">
			<h3 className="fw--800 mb1">{symptomsTitle}</h3>
			<ul className="df fdr fw">
				{symptoms.map(({title, _key}, index) => (
					<li key={index} className="mr1 mb1">
						<p
							className="button--pill--primary no-hover"
							style={{backgroundColor: color}}>
							{title}
						</p>
					</li>
				))}
			</ul>
		</div>
	);

	const ingredientList = (
		<div className="ingredient-list-product-details">
			<h3 className="fw--800 mb1">{ingredientsTitle}</h3>
			<ul className="df fdr fw">
				{ingredients.map(({title, _key}, index) => (
					<li key={index} className="mr1 mb1">
						<p
							className="button--pill--primary no-hover"
							style={{backgroundColor:color}}>
							{title}
						</p>
					</li>
				))}
			</ul>
		</div>
	);

	return (
		<div className="pt6 mb6 bt--black">
			<div className="grid-container contained">
				<div className="row">
					<div className="col c1 show--xl" />

					<div className="col c6--md c4--xl mb5 mb0--md">
						{symptomList}
						{ingredientList}
					</div>

					<div className="col c2 show--xl" />

					<div className="col c6--md c4--xl">
						<div className="rich-text line-break">
							<BlockContent
								blocks={productDetails}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
