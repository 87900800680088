import React from 'react';

export default ({title, _id}) => (
	<div>
		<div className="pt6 pt12--md bg--white edge--small" id="product-reviews"/>
		<div className="grid-container contained">
			<div className="row align--center">
				<div className="col c10--lg">
					<h2 className="sans--lg fw--800 mb6">{title}</h2>
					<div className="product-reviews">
						<div data-bv-show="reviews" data-bv-product-id={_id} />
					</div>
				</div>
			</div>
		</div>
	</div>
);
