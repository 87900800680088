import React from 'react';
import Link from './Link';

export default ({title, pressItems = []}) => (
	<div className="bg--gray-bg pt5 pb7">
		<div className="of--hidden grid-container contained">
			<h2 className="mb3 fw--800 tc color--black">{title}</h2>

			<div className="ofx--scroll certifications-grid__list">
				<ul className="df fdr jcs jcc--lg aic">
					{pressItems.map(item => (
						<li key={item._key}>
							<div className="pr6 pl3--lg pr3--lg pr4--xl pl4--xl">
								{item.cta ? (
									<Link {...item.cta} aria-label={item.logo.alt}>
										<img
											alt={item.logo.alt}
											src={`${item.logo.url}?w=680&auto=format`}
											className="db certifications-grid__certification"
										/>
									</Link>
								) : (
									<img
										alt={item.logo.alt}
										src={`${item.logo.url}?w=680&auto=format`}
										className="db certifications-grid__certification"
									/>
								)}
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	</div>
);
