import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {connectToStore} from './Provider';
import viewport from 'magic-tricks/lib/viewport';
import scrollTo from 'magic-tricks/lib/scrollTo';
import reduce from 'lodash/reduce';

const ProductStickyNav = ({
	hasClosedBanner,
	customer,
	enableBanner,
	customerDiscountTags,
	title,
}) => {
	const scrollRef = useRef(null);
	const [isActive, setActive] = useState(false);

	const tags = customer ? customer.tags || [] : [];

	const isCustomerDiscount = reduce(
		tags,
		(isCustomerDiscount, tag) => {
			if (customerDiscountTags.indexOf(tag) > -1) {
				return true;
			} else return isCustomerDiscount;
		},
		false,
	);

	const onScroll = () => {
		if (!scrollRef.current) return;

		const position = scrollRef.current.getBoundingClientRect().top;

		if (position <= 0) {
			setActive(true);
		} else {
			setActive(false);
		}
	};

	const onClickBuy = () => {
		scrollTo(0);
	};

	useEffect(() => {
		onScroll();

		viewport.listen(onScroll);

		return () => {
			viewport.unlisten(onScroll);
		};
	}, []);

	const nav = (
		<div
			className={classNames(
				'product-sticky-nav df fdc aic jcc bt--black',
				{
					active: isActive,
					bannerVisible: !hasClosedBanner && enableBanner,
					discountBannerVisible: isCustomerDiscount,
				},
			)}>
			<div className="x">
				<div className="grid-container contained">
					<div className="row align--center">
						<div className="col c10--xl">
							<div className="df fdr jcb aic">
								<p className="fw--800 sans--lg--lg">{title}</p>
								<button
									title="Buy Now"
									aria-label="Buy Now"
									onClick={onClickBuy}
									className="db button--primary show--lg">
									Buy Now
								</button>
								<button
									title="Buy Now"
									aria-label="Buy Now"
									onClick={onClickBuy}
									className="db button--primary--small hide--lg">
									Buy Now
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<React.Fragment>
			<div ref={scrollRef} />
			{nav}
		</React.Fragment>
	);
};

const mapStateToProps = ({hasClosedBanner, customer}) => ({
	hasClosedBanner,
	customer,
});

export default connectToStore(mapStateToProps)(ProductStickyNav);
