import React, {useState, useEffect} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import {connectToStore} from './Provider';
import clientSideComponent from '../middleware/clientSideComponent';
import {compose} from 'recompose';
import {Transition} from 'react-transition-group';

const POPUP_TIMEOUT = 4000;
export const MODAL_TRANSITION_DURATION = 600;

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translateY(20px)',
	},
	entered: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translateY(20px)',
	},
	exited: {
		opacity: 0,
		transform: 'translateY(20px)',
	},
};

const KlaviyoPopup = ({callToKlaviyoAPI, closePopup, isVisible, hasClosedPopup, popupOverride, klaviyoError}) => {
	const [showPopup, setShowPopup] = useState(isVisible);
	const [userEmail, setUserEmail] = useState('');

	const closeThisPopup = () => {
		closePopup();
		setShowPopup(false);
	}

	useEffect(() => {
			setShowPopup(isVisible);
	}, [isVisible]);


	const submitKlaviyo = () => {
		callToKlaviyoAPI(userEmail);
	}

	const updateUserEmail = (e) => {
		setUserEmail(e.target.value)
	}

	return (
		<Transition
			in={showPopup}
			appear
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<div className="popup__wrapper">
				<div
					className="landing__page__popup"
					style={{
						...MODAL_TRANSITION_STYLES.default,
						...MODAL_TRANSITION_STYLES[status],
					}}>
					<div className="popup__image">
						<div style={{
							backgroundImage: "url(" + "/images/0e5827cc-ab2b-4dc4-936a-b371a499ce1c.jpg" + ")",
							backgroundPosition: 'center',
						  backgroundSize: 'cover',
						  backgroundRepeat: 'no-repeat',
							width: '100%',
							height: '100%',
							backgroundSize: '425px'
							}} />
					</div>
					<div className="flex__box">
						<div>
							<div className="p2 pr4">
								<div className="h3">Notify Me When Available</div>
							</div>
							<div className="p1 pr4 rich-text">
								<>
									<p>Register to receive a notification when this item comes back in stock.</p>
									<input value={userEmail} onChange={(e) => updateUserEmail(e) } type="text" id="u_email" className="user__email" name="u_email" />
  								<input onClick={() => submitKlaviyo() } className="button--primary submit__klaviyo" type="submit" value="Submit" />

									<div className="klaviyo__error">
										{ klaviyoError }
									</div>
								</>
							<div className="continue__shopping">
								<a  title="Shop Now" href="/collections/all" aria-label="Continue Shopping">Continue Shopping</a>
							</div>
						</div>
						<div className="pa top right p1 pen">
							<button
								title="Close"
								aria-label="Close" 
								onClick={() => closeThisPopup() }
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-close-filter.svg"
									alt="Close"
								/>
							</button>
						</div>
						</div>
					</div>
				</div>
				</div>
			)}
		</Transition>
	);
};

const mapStateToProps = ({hasClosedPopup}) => ({
	hasClosedPopup,
});

const mapStoreToProps = ({closePopup}) => ({
	closePopup,
});

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps, mapStoreToProps),
)(KlaviyoPopup);
