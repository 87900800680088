import React from 'react';

export default ({certifications, backgroundColor}) => (
	<div
		className="pt4 pb4 pt8--md pb8--md ofx--scroll z1"
		style={{backgroundColor: backgroundColor || '#f2f2f2'}}>
		<div className="grid-container contained">
			<ul className="df aic jcs jcc--sm">
				{certifications.map(({logo}, index) => (
					<li className="db pr3 pl3--sm" key={index}>
						<img
							className="db product__certification"
							src={logo.url}
							alt={logo.alt}
						/>
					</li>
				))}
			</ul>
		</div>
	</div>
);
